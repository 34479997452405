/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .StripeElement {
    box-sizing: border-box;
  
    height: 40px;
  
    padding: 10px 12px;
  
    border: 1px solid transparent;
    border-radius: 4px;
    
    min-height: 53px;
    padding-top: 16px;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement.StripeElement--empty:hover {
    border: 1px solid rgb(60, 60, 60)
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
    border: 2px solid #5C65DF
  }

  .StripeElement.StripeElement--empty.StripeElement--focus {
    border: 2px solid #5C65DF
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .invalid .StripeElement {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .StripeElement--empty, .StripeElement--complete{
    border: 1px solid rgb(180, 180, 180)
  }